import { Modal, TertiaryLink } from '@everlywell/leaves';
import RichTextWrapper from 'components/RichTextWrapper';
import React, { FC, useState, useEffect } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ContentfulRichText } from 'utils/contentful/renderRichText';

import * as S from './GeneralDisclaimer.styles';

export type CallToAction = {
  text: string;
  url: string;
};

export type DisclaimerDataProps = {
  body: ContentfulRichText;
  body2?: ContentfulRichText;
  body3?: ContentfulRichText;
  callToAction: CallToAction;
  callToAction2?: CallToAction;
  title: string;
};

export interface Props extends DisclaimerDataProps {
  open: boolean;
  openHandler: (x: boolean) => void;
}

const GeneralDisclaimer: FC<Props> = (props: Props) => {
  const {
    callToAction,
    callToAction2,
    body,
    body2,
    body3,
    title,
    open,
    openHandler,
  } = props;
  const { text, url } = callToAction;

  const [isCurrentLocation, setIsCurrentLocation] = useState(false);

  const label = text || 'Close';

  const modalHandler = (event: boolean) => openHandler && openHandler(event);

  const handleOnClick = () => {
    modalHandler(false);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.PRE_PURCHASE_DISCLAIMER_CTA,
      },
    });
  };

  /**
   * Mount Effect
   */
  useEffect(() => {
    if (window?.location) {
      setIsCurrentLocation(window.location.pathname === url);
    }
  }, [url]);

  return (
    <Modal openHandler={modalHandler} open={open}>
      <S.Container>
        <S.Title>{title}</S.Title>
        <S.Body>
          <RichTextWrapper contentfulRichText={body} />
        </S.Body>
        <S.Footer>
          {url && url.length && !isCurrentLocation ? (
            <S.CTA appearance="primary" onClick={handleOnClick} href={url}>
              {label}
            </S.CTA>
          ) : (
            <S.CTA appearance="primary" onClick={handleOnClick}>
              {label}
            </S.CTA>
          )}
        </S.Footer>

        {body2 && (
          <S.Body>
            <RichTextWrapper contentfulRichText={body2} />
          </S.Body>
        )}
        {callToAction2 && (
          <S.LinkWrapper>
            <TertiaryLink href={callToAction2.url}>
              {callToAction2.text}
            </TertiaryLink>
          </S.LinkWrapper>
        )}
        {body3 && (
          <S.Body>
            <RichTextWrapper contentfulRichText={body3} />
          </S.Body>
        )}
      </S.Container>
    </Modal>
  );
};

export default GeneralDisclaimer;
