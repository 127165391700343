import loadable, { LoadableComponent } from '@loadable/component';
import CompactListSection, {
  CompactListSectionProps,
} from 'components/PDP/CompactListSection';
import { ComparisonSectionProps } from 'components/PDP/ComparisonSection';
import { StructuredData } from 'components/StructuredData';
import { structuredProductData } from 'components/StructuredData/structuredDataHelpers';
import { HealthPathContainerProps } from 'containers/PDP/HealthPathContainer';
import { UnderstandingTheTestContainerProps } from 'containers/PDP/UnderstandingTheTestContainer';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import { useQueryParam, NumberParam } from 'use-query-params';
import ewAnalytics from 'utils/analytics';
import { getNYProducts, NyProduct } from 'utils/api/productsApi';
import { ANALYTICS } from 'utils/constants/analytics';
import { REGION_IDS } from 'utils/constants/queryParams';
import { APP_ROOT } from 'utils/constants/urls';
import {
  getUpdatedProductBySlug,
  trackIterable,
  getProductBadges,
} from 'utils/helpers';
import { getActivePromo, Promo } from 'utils/helpers/activePromoHelpers';
import {
  AllProductPromosContext,
  ComparisonModalContext,
  ProductInfoContext,
} from 'utils/helpers/pdpContexts';
import {
  usePdpPageData,
  useProductData,
  useProductVariants,
  useProductBySlug,
  usePromoData,
} from 'utils/hooks';
import { PDPPageSectionType } from 'utils/types';
import { v4 as uuidv4 } from 'uuid';

import Layout from '../components/Layout';
import { SEO } from '../components/SEO';
import { DigitalResultsContainerProps } from '../containers/DigitalResultsContainer';
import {
  FAQContainer as FAQSection,
  FAQContainerProps,
} from '../containers/FAQContainer';
import { HowItWorksContainerProps } from '../containers/HowItWorksContainer';
import { DetailsContainerProps } from '../containers/PDP/DetailsContainer';
import { HeroContainer as HeroSection } from '../containers/PDP/HeroContainer';
import { MoreTestsContainerProps } from '../containers/PDP/MoreTestsContainer';
import { ReviewsContainerProps } from '../containers/PDP/ReviewsContainer';
import { TestimonialsContainerProps } from '../containers/PDP/TestimonialsContainer';
import { WhatToExpectContainerProps } from '../containers/PDP/WhatToExpectContainer';
import { WhyTrustUsContainerProps } from '../containers/PDP/WhyTrustUsContainer';
import { PrePurchaseModalContextProvider } from '../contexts/PrePurchaseModal';
import { UserSettingsProvider } from '../utils/hooks/useAccountSettings/context';
import { CreditRedemptionModalProvider } from '../utils/hooks/useCreditRedemptionModal/context';
import useDisplayedSectionFilterForMembers from '../utils/hooks/useDisplayedSectionFilterForMembers';

const DetailsSection = loadable(
  () => import('../containers/PDP/DetailsContainer'),
);
const DigitalResultsSection = loadable(
  () => import('../containers/DigitalResultsContainer'),
);
const HowItWorksSection = loadable(
  () => import('../containers/HowItWorksContainer'),
);
const ReviewsSection = loadable(
  () => import('../containers/PDP/ReviewsContainer'),
);
const MoreTestsSection = loadable(
  () => import('../containers/PDP/MoreTestsContainer'),
);
const TestimonialsSection = loadable(
  () => import('../containers/PDP/TestimonialsContainer'),
);
const WhyTrustUsSection = loadable(
  () => import('containers/PDP/WhyTrustUsContainer'),
);
const ComparisonSection = loadable(
  () => import('components/PDP/ComparisonSection'),
);
const UnderstandingTheTestSection = loadable(
  () => import('containers/PDP/UnderstandingTheTestContainer'),
);
const HealthPathSection = loadable(
  () => import('containers/PDP/HealthPathContainer'),
);
const ComparisonTable = loadable(
  () => import('../containers/PDP/ComparisonTableContainer'),
);
const WhatToExpectSection = loadable(
  () => import('../containers/PDP/WhatToExpectContainer'),
);

const components: {
  DetailsSection: LoadableComponent<DetailsContainerProps>;
  WhyTrustUsSection: LoadableComponent<WhyTrustUsContainerProps>;
  DigitalResultsSection: LoadableComponent<DigitalResultsContainerProps>;
  HowItWorksSection: LoadableComponent<HowItWorksContainerProps>;
  ReviewsSection: LoadableComponent<ReviewsContainerProps>;
  FAQSection: FunctionComponent<FAQContainerProps>;
  TestimonialsSection: LoadableComponent<TestimonialsContainerProps>;
  MoreTestsSection: LoadableComponent<MoreTestsContainerProps>;
  ComparisonSection: LoadableComponent<ComparisonSectionProps>;
  CompactListSection: FunctionComponent<CompactListSectionProps>;
  UnderstandingTheTestSection: LoadableComponent<UnderstandingTheTestContainerProps>;
  HealthPathSection: LoadableComponent<HealthPathContainerProps>;
  ComparisonTable: LoadableComponent<unknown>;
  WhatToExpectSection: LoadableComponent<WhatToExpectContainerProps>;
} = {
  DetailsSection,
  WhyTrustUsSection,
  DigitalResultsSection,
  HowItWorksSection,
  ReviewsSection,
  FAQSection,
  TestimonialsSection,
  MoreTestsSection,
  ComparisonSection,
  CompactListSection,
  ComparisonTable,
  UnderstandingTheTestSection,
  HealthPathSection,
  WhatToExpectSection,
};

interface Props {
  pageContext: {
    advertisedPromos: Promo[];
    pageSlug: string;
    showFDADisclaimer?: boolean;
  };
}

const PDPTemplate = (props: Props): JSX.Element => {
  const { pageContext } = props;
  const { advertisedPromos, pageSlug, showFDADisclaimer = false } = pageContext;
  const { allContentfulPdpPage, allReviews } = usePdpPageData();
  const productVariants = useProductVariants();
  const prodBySlug = useProductBySlug(productVariants);
  const allProductPromoData = usePromoData();
  const productData = useProductData(
    allContentfulPdpPage,
    productVariants,
    pageSlug,
  );

  const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [sentAnalytics, setSentAnalytics] = useState(false);

  const [regionId] = useQueryParam('region_id', NumberParam);

  // Fetch products available in NY
  const { data } = useQuery(`ny-products`, () => getNYProducts(), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  const nyProductSlugs = data?.map((product: NyProduct) => product.slug) ?? [];

  // Used by Facebook Conversions API
  const eventId = uuidv4();

  // At build time, Store provides an array of future scheduled promotions with
  // `advertised=true`. If one is active it will be used for strikethrough pricing
  const activePromo = useMemo(
    () => getActivePromo(advertisedPromos),
    [advertisedPromos],
  );

  // Generate props for the content sections.
  // `promo` is only needed by the ComparisonContentSection (for displaying discounted prices)
  const buildSectionProps = (section: PDPPageSectionType) => {
    const promoProp = section.componentName === 'ComparisonSection' && {
      promo: activePromo,
    };
    return {
      content: section,
      key: section.componentName,
      ...promoProp,
    };
  };

  useEffect(() => {
    // This state variable is used to track the first render of this component,
    // otherwise the tracking and analytics events will be fired every time
    // this component is rendered
    if (sentAnalytics) {
      return;
    }
    setSentAnalytics(true);

    trackIterable('pdpPageLoad', eventId, {
      id: productData?.productId,
      name: productData?.name,
      price: parseFloat(productData?.price),
    });

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        Page: 'PDP',
        sku: productData?.sku,
      },
    });
  }, [
    eventId,
    productData?.name,
    productData?.price,
    productData?.productId,
    productData?.sku,
  ]);

  const trackScrolledIntoView = (inView: boolean) => {
    if (inView) {
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.SCROLLED_TO_BOTTOM,
        data: {
          label: ANALYTICS.LABELS.PDP,
          sku: productData?.sku,
        },
      });
    }
  };

  const [footerRef] = useInView({
    triggerOnce: true,
    onChange: trackScrolledIntoView,
  });

  const preconnectLinks = [
    'https://www.google-analytics.com',
    'https://d18p8z0ptb8qab.cloudfront.net',
    'https://aa.agkn.com',
    'https://cnv.event.prod.bidr.io',
    'https://everlywell.extole.io',
    'https://www.googletagmanager.com',
  ];

  const {
    pageSections: sections = [],
    heroSection,
    hideSubscription,
    preSelectedSubscription,
    heroSubscriptionFaqUrl,
    disclaimer,
    screener,
    digDeeperSections,
  } = productData || {};

  const [displayedSections] = useDisplayedSectionFilterForMembers(
    sections,
  ) as unknown as PDPPageSectionType[][];

  if (!productData) {
    return <React.Fragment />;
  }

  const productsBySlug = getUpdatedProductBySlug(
    allContentfulPdpPage,
    productVariants,
    allReviews,
    prodBySlug,
  );
  const {
    consultAvailable,
    consultAvailableText,
    heroMeasuresText,
    heroMeasuresIcon,
    heroDescriptionHeader,
    heroDescriptionText,
    heroSliderImages,
    heroMeasuresContentSection,
    heroModalTitle,
    heroModalButtonText,
    heroDescriptionCallout,
    heroPurchaseDetailsCallout,
    heroPurchaseDetailsBottomCallout,
  } = heroSection;

  const getCanonicalUrl = () => {
    if (productData.canonicalUrl) {
      return `products/${productData.canonicalUrl}`;
    }

    if (productData.slug) {
      return `products/${productData.slug}`;
    }

    return undefined;
  };

  // To maintain Google Merchant account compliance, we are required to
  // show products as unavailable in the case that a user visits from
  // a region in which we do not offer a product. This is determined
  // by a region_id param appended to PDP links from the shopping feed
  // https://support.google.com/merchants/answer/9698880?hl=en

  const regionalRestrictionApplies =
    regionId === REGION_IDS.NEW_YORK &&
    !nyProductSlugs.includes(productData.sku);

  // Validate if product has screener and disclaimer
  const hasPrePurchaseStep = !!(screener && disclaimer);

  if (!productData) {
    return <React.Fragment />;
  }

  return (
    <div data-testid="pdp-page">
      <SEO
        title={productData.metaTitle}
        description={productData.metaDescription}
        keywords={productData.metaKeywords}
        url={`${APP_ROOT}/products/${productData.slug}/`}
        faqSchema={digDeeperSections.map((section) => ({
          question: section.question,
          answer: section.answer.answer,
        }))}
        slug={productData.slug}
        canonicalUrl={getCanonicalUrl()}
      />
      <StructuredData
        data={structuredProductData(
          productData,
          activePromo,
          regionalRestrictionApplies,
        )}
      />
      <Helmet>
        {preconnectLinks.map((link: string, index) => (
          <link
            key={index}
            href={link}
            rel="preconnect"
            crossOrigin="anonymous"
          />
        ))}
      </Helmet>
      {sections ? (
        <Layout
          productsBySlug={productsBySlug}
          viewEventId={eventId}
          productId={productData.productId}
          footerRef={footerRef}
          showFDADisclaimer={showFDADisclaimer}
        >
          <ProductInfoContext.Provider
            value={{
              ...productData,
              heroImages: heroSection.heroSliderImages,
              productsBySlug,
            }}
          >
            <UserSettingsProvider>
              <CreditRedemptionModalProvider>
                <AllProductPromosContext.Provider value={allProductPromoData}>
                  <PrePurchaseModalContextProvider>
                    <ComparisonModalContext.Provider
                      value={{
                        comparisonModalOpen,
                        handleComparisonModalClick: () =>
                          setComparisonModalOpen(!comparisonModalOpen),
                      }}
                    >
                      <div id="banner"></div>
                      <HeroSection
                        consultAvailable={consultAvailable}
                        consultAvailableText={consultAvailableText}
                        descriptionHeader={heroDescriptionHeader}
                        descriptionText={heroDescriptionText}
                        descriptionCallout={heroDescriptionCallout}
                        measuresText={heroMeasuresText}
                        measuresIcon={heroMeasuresIcon?.file.url}
                        modalButtonText={heroModalButtonText}
                        modalTitle={heroModalTitle}
                        listOfMeasuredItems={heroMeasuresContentSection}
                        sliderImages={heroSliderImages}
                        hideSubscription={hideSubscription}
                        preSelectedSubscription={preSelectedSubscription}
                        heroSubscriptionFaqUrl={heroSubscriptionFaqUrl}
                        hasPrePurchaseStep={hasPrePurchaseStep}
                        ctaSubheadline={
                          heroPurchaseDetailsCallout ||
                          'Free Shipping • FSA / HSA accepted' // This is the default value unless specified in Contentful
                        }
                        detailsBottomCallout={heroPurchaseDetailsBottomCallout}
                        promo={activePromo}
                        regionalRestrictionApplies={regionalRestrictionApplies}
                        screener={screener}
                        disclaimer={disclaimer}
                        badges={getProductBadges(productData)}
                      />

                      {displayedSections.map((section) => {
                        switch (section.componentName) {
                          case 'CompactListSection':
                            const contentSections =
                              section.listOfContentSections
                                ?.map((section) => ({
                                  headline: section.headline ?? '',
                                  image: section.image,
                                }))
                                .filter(
                                  (section) =>
                                    Boolean(section.image) &&
                                    Boolean(section.headline),
                                );
                            return (
                              contentSections && (
                                <CompactListSection
                                  listOfContentSections={contentSections}
                                />
                              )
                            );
                          case 'FAQSection': {
                            return (
                              <FAQSection
                                content={{
                                  title: section.title,
                                }}
                              />
                            );
                          }
                          default:
                            const SectionComponent =
                              components[
                                section.componentName as keyof typeof components
                              ];

                            if (SectionComponent) {
                              const props = buildSectionProps(section);
                              return React.createElement(
                                // @ts-ignore - TS doesn't know to match the component with it's proper types
                                SectionComponent,
                                // @ts-ignore - FIXME: incorrect type matching
                                props,
                              );
                            }

                            return null;
                        }
                      })}
                    </ComparisonModalContext.Provider>
                  </PrePurchaseModalContextProvider>
                </AllProductPromosContext.Provider>
              </CreditRedemptionModalProvider>
            </UserSettingsProvider>
          </ProductInfoContext.Provider>
        </Layout>
      ) : null}
    </div>
  );
};

export default PDPTemplate;
