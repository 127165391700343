import { Modal, RadioButton } from '@everlywell/leaves';
import React, { useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';

import * as S from './styles';

export enum OptionsValues {
  eligible = 'eligible',
  ineligible = 'ineligible',
}

type Option = {
  items: string[] | null;
  label: string;
  value: OptionsValues;
};

export type ScreenerDataProps = {
  callToActionText: string;
  options: Option[] | null;
  title: string;
  description?: string;
};

export type Props = {
  eligibleOptionCallback: (event: React.FormEvent<HTMLButtonElement>) => void;
  ineligibleOptionCallback: (event: React.FormEvent<HTMLButtonElement>) => void;
  modalOpen: boolean;
  modalOpenCallback: (open: boolean) => void;
  screenerData: ScreenerDataProps;
};

const PrePurchaseScreener: React.FC<Props> = (props: Props) => {
  const {
    eligibleOptionCallback,
    ineligibleOptionCallback,
    modalOpen,
    modalOpenCallback,
    screenerData,
  } = props;

  const { callToActionText, options, title, description } = screenerData;

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const isCTADisabled = !(selectedOption && selectedOption.value);

  const modalOpenHandler = (open: boolean) => {
    setSelectedOption(null);
    modalOpenCallback(open);
  };

  const handleTrackEvent = (option: Option | null) => {
    if (option) {
      const { label, value } = option;
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.PRE_PURCHASE_SCREENER_ANSWER,
        data: {
          question: title,
          answer: label,
          value: value,
        },
      });
    }
  };

  const handleOnCTAClick = (event: React.FormEvent<HTMLButtonElement>) => {
    const { value } = selectedOption || {};

    if (value === OptionsValues.eligible) {
      eligibleOptionCallback(event);
    } else {
      ineligibleOptionCallback(event);
    }

    handleTrackEvent(selectedOption);
    modalOpenHandler(false);
  };

  return modalOpen ? (
    <Modal openHandler={modalOpenHandler} open={modalOpen}>
      <S.Container>
        <S.Title>{title}</S.Title>
        {description && <S.Description>{description}</S.Description>}

        {options ? (
          <S.Body data-testid="options">
            {options.map((option: Option) => {
              const { label, value, items } = option;

              return (
                <S.RadioOption
                  key={label}
                  onClick={() => setSelectedOption(option)}
                >
                  <S.RadioOptionHeading>
                    <RadioButton
                      id={label}
                      value={value}
                      checked={
                        selectedOption ? selectedOption.label === label : false
                      }
                      name="option"
                    />

                    <S.OptionTitle>{label}</S.OptionTitle>
                  </S.RadioOptionHeading>

                  {items ? (
                    <S.RadioOptionContent>
                      <S.UL>
                        {items.map((item: string, idx: number) => (
                          <S.LI key={idx}>{item}</S.LI>
                        ))}
                      </S.UL>
                    </S.RadioOptionContent>
                  ) : null}
                </S.RadioOption>
              );
            })}
          </S.Body>
        ) : null}

        <S.Footer>
          <S.CTA
            data-testid="cta"
            appearance="primary"
            // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
            onClick={handleOnCTAClick}
            isDisabled={isCTADisabled}
          >
            {callToActionText}
          </S.CTA>
        </S.Footer>
      </S.Container>
    </Modal>
  ) : null;
};

export default PrePurchaseScreener;
