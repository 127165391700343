import { useCart } from 'components/Cart';
import { PurchaseType } from 'components/Hero/PDPHero/PurchaseControls';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import Cookies from 'js-cookie';
import React, { useContext, useMemo, useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CookieNames } from 'utils/constants/cookies';
import { Promo } from 'utils/helpers/activePromoHelpers';
import { ProductInfoContext } from 'utils/helpers/pdpContexts';
import { useMembershipProducts } from 'utils/hooks';
import { UserContext } from 'utils/hooks/useUserContext/context';
import {
  CollectionMethodContentfulType,
  ImageType,
  ProductBadgeConfig,
  Variant,
} from 'utils/types';

import { DisclaimerDataProps } from '../../../components/GeneralDisclaimer';
import { PDPHero } from '../../../components/Hero';
import { ScreenerDataProps } from '../../../components/PrePurchaseScreener';
import PrePurchaseWrapper from '../../../components/PrePurchaseWrapper';
import { UserSettingsProvider } from '../../../utils/hooks/useAccountSettings/context';
import microscope from './microscope.svg';
import stethoscope from './stethoscope.svg';
export type SubscriptionOptionType = {
  id: number;
  label: string;
  name: string;
  savingsStatement: string;
  percentDiscount: number;
  price: string;
};
export type HeroContainerProps = {
  consultAvailable?: boolean;
  consultAvailableText: string;
  descriptionHeader: string;
  descriptionText: string;
  measuresText: string;
  measuresIcon?: string;
  sliderImages: ImageType[];
  modalTitle?: string;
  modalButtonText?: string;
  listOfMeasuredItems?: {
    listOfContentSections: Array<{
      description: {
        description: string;
      };
      title: string;
      image: ImageType;
    }>;
  };
  hideSubscription?: boolean;
  preSelectedSubscription?: boolean;
  heroSubscriptionFaqUrl?: string;
  descriptionCallout?: string;
  ctaSubheadline: string;
  promo?: Promo;
  regionalRestrictionApplies?: boolean;
  hasPrePurchaseStep?: boolean;
  disclaimer: DisclaimerDataProps | null;
  screener: ScreenerDataProps | null;
  detailsBottomCallout?: string;
  badges: ProductBadgeConfig[];
};
export const HeroContainer: React.FC<HeroContainerProps> = ({
  consultAvailable,
  consultAvailableText,
  descriptionHeader,
  descriptionText,
  measuresText,
  measuresIcon,
  sliderImages,
  modalButtonText,
  modalTitle,
  listOfMeasuredItems,
  hideSubscription,
  preSelectedSubscription,
  heroSubscriptionFaqUrl,
  descriptionCallout,
  ctaSubheadline,
  promo,
  regionalRestrictionApplies,
  hasPrePurchaseStep,
  disclaimer,
  screener,
  detailsBottomCallout,
  badges,
}) => {
  const { addItemToCartAsync } = useCart();

  const { userData } = useContext(UserContext);
  const productData = useContext(ProductInfoContext);

  const productWithMembership = useMembershipProducts(
    userData,
    productData.productsBySlug,
  );
  const {
    name,
    price,
    reviewData,
    collectionMethods,
    subscriptionVariants = [],
    variantId,
    productId,
    primaryCallouts,
    outOfStock,
    promotionable,
    maxQuantity,
    showRating,
  } = productData;
  const { membershipVariantId, memberPrice, memberHeroCopy, activeCredits } =
    productWithMembership[productData.slug] ?? {};
  let reviewCount = 0;
  let averageRating = 0;
  if (reviewData) {
    reviewCount = reviewData.review_count;
    averageRating = reviewData.average_rating;
  }
  const experimentCookie = Cookies.get(CookieNames.HomepageExperiment);

  const images = sliderImages.map((img) => ({
    image: (
      <Img
        loading="eager"
        image={img.gatsbyImageData}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 1,
          transform: 'none',
          transition: 'none',
          objectFit: 'contain',
        }}
        imgStyle={{
          objectFit: 'contain',
          opacity: 1,
          transform: 'none',
          transition: 'none',
        }}
        alt={img.description}
      />
    ),
    imageAlt: img.description,
    id: img.id,
  }));
  const methods = collectionMethods.map(
    (method: CollectionMethodContentfulType) => ({
      name: method.v2Name ? method.v2Name : '',
      iconUrl: method.v2Icon ? method.v2Icon.file.url : '',
    }),
  );
  const measurements = [
    { name: measuresText, iconUrl: measuresIcon || microscope },
  ];
  const marketingCallout =
    primaryCallouts && primaryCallouts.length
      ? primaryCallouts[0].text
      : undefined;
  // Format subscription options
  const subscriptionOptionsByName = useMemo(
    () =>
      subscriptionVariants.reduce(
        (acc: { [key: string]: SubscriptionOptionType }, v: Variant) => {
          const {
            subscription_plan: subscriptionPlan,
            id,
            price: subscriptionPrice,
          } = v;
          const { name, percent_discount: percentDiscount } =
            subscriptionPlan || {};
          const savings = parseInt(price) - parseInt(subscriptionPrice);
          acc[String(name)] = {
            id,
            price: subscriptionPrice,
            label: `${name} Subscription: $${parseInt(
              subscriptionPrice,
            ).toFixed(0)}`,
            percentDiscount: percentDiscount ?? 0,
            name: String(name),
            savingsStatement: `YOU SAVE $${savings.toFixed(
              0,
            )} (${percentDiscount}%)`,
          };
          return acc;
        },
        {},
      ),
    [price, subscriptionVariants],
  );
  // Default subscription option was 2nd but now we have products with only one subscription option
  const defaultSubscriptionOption =
    Object.keys(subscriptionOptionsByName)[2] ??
    Object.keys(subscriptionOptionsByName)[0];
  const [activeSubscriptionOption, setActiveSubscriptionOption] =
    useState<SubscriptionOptionType>(
      subscriptionOptionsByName[defaultSubscriptionOption],
    );
  const handleSubscriptionOptionChange = (optionName: string) => {
    setActiveSubscriptionOption(subscriptionOptionsByName[optionName]);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CHANGED_FREQUENCY,
      data: { frequency: optionName },
    });
  };

  const getPreSelectedSubscription = () =>
    preSelectedSubscription ? 'subscribe' : 'oneTimePurchase';

  // Persist value to be used by handleExpressPayClick
  const storePreSelectedSubscription = (preDefinedValue: string) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('purchaseType', preDefinedValue);
    }
  };

  const [activePurchaseType, setActivePurchaseType] = useState<PurchaseType>(
    () => {
      const preDefinedValue = getPreSelectedSubscription();
      storePreSelectedSubscription(preDefinedValue);

      return preDefinedValue;
    },
  );
  const handlePurchaseTypeChange = (purchaseType: PurchaseType) => {
    setActivePurchaseType(purchaseType);
    storePreSelectedSubscription(purchaseType);

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.PURCHASE_TYPE,
      data: { type: purchaseType, product: name, sku: productData.sku },
    });
  };
  const [quantity, setQuantity] = useState<number>(1);
  const handleExpressPayClick = (cb: () => void) => {
    const storedActiveSubscription =
      localStorage.getItem('purchaseType') || activePurchaseType;

    addItemToCartAsync({
      variant_id:
        storedActiveSubscription === 'subscribe'
          ? activeSubscriptionOption.id
          : variantId,
      quantity: 1,
    }).then(() => cb());
  };
  /**
   * Add to cart click event handler
   * @param e React event
   * @param callback function to be triggered on click
   * @returns void
   */
  const handleAddToCartClick = (
    e: React.FormEvent<HTMLButtonElement>,
    callback?: () => void,
  ) => {
    const target = e.target as HTMLInputElement;
    const label =
      target.dataset.name === ANALYTICS.LABELS.ADD_TO_CART_MODAL
        ? ANALYTICS.LABELS.ADD_TO_CART_MODAL
        : ANALYTICS.LABELS.ADD_TO_CART;
    const sku = target.dataset.sku;
    ewAnalytics.track({
      data: {
        label,
        'Test ID': productId,
        'Test Name': name,
        'Purchase Type': activePurchaseType,
        Sku: sku ? sku : '',
        Experiment: experimentCookie ? experimentCookie : '',
      },
      event: ANALYTICS.EVENTS.ADD_TO_CART,
    });

    const variant_id =
      activePurchaseType === 'subscribe'
        ? activeSubscriptionOption.id
        : variantId;
    if (variant_id) {
      addItemToCartAsync({ variant_id, quantity }).then(() => callback?.());
    }
  };
  const handleQuantityPickerChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setQuantity(+target.value);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.QUANTITY_SELECT,
      data: {
        name,
        productId,
        quantity: target.value,
      },
    });
  };
  const formattedMeasuredItems =
    listOfMeasuredItems?.listOfContentSections?.map((item) => {
      const formattedItemNames = item.description?.description.split('\n');
      return {
        image: item.image?.file?.url || '',
        title: item.title,
        items: formattedItemNames,
      };
    }) || [];
  //TODO: Remove this once Food Sensitivity a/b test finish

  // Only hide rating if it comes as false from contentful. Null or true means: show it
  const showShowRating = showRating === null || showRating === true;

  return (
    <UserSettingsProvider>
      <PrePurchaseWrapper addToCart={handleAddToCartClick} />
      <PDPHero
        productId={productId}
        name={name}
        descriptionHeader={descriptionHeader}
        description={descriptionText}
        price={price}
        memberPrice={memberPrice}
        memberHeroCopy={memberHeroCopy}
        membershipVariantId={membershipVariantId ?? 0}
        activeCredits={activeCredits}
        averageRating={averageRating}
        reviewCount={reviewCount}
        sliderImages={[...images]}
        collectionMethods={methods}
        consultAvailable={consultAvailable}
        consultAvailableText={consultAvailableText}
        consultIcon={stethoscope}
        measurements={measurements}
        subscriptionOptions={Object.values(subscriptionOptionsByName)}
        onSubscriptionOptionChange={handleSubscriptionOptionChange}
        activeSubscriptionOption={activeSubscriptionOption}
        activePurchaseType={activePurchaseType}
        onPurchaseTypeChange={handlePurchaseTypeChange}
        onAddToCartClick={handleAddToCartClick}
        onExpressPayClick={handleExpressPayClick}
        subscribeSelected={activePurchaseType === 'subscribe'}
        onQuantityPickerChange={handleQuantityPickerChange}
        quantity={quantity}
        topCallout={undefined}
        bottomCallout={marketingCallout}
        descriptionCallout={descriptionCallout}
        modalButtonText={modalButtonText}
        modalTitle={modalTitle}
        listOfMeasuredItems={formattedMeasuredItems}
        hideSubscription={subscriptionVariants.length === 0 || hideSubscription}
        heroSubscriptionFaqUrl={heroSubscriptionFaqUrl}
        ctaSubheadline={ctaSubheadline}
        isOutOfStock={Boolean(outOfStock)}
        promo={promo}
        promotionable={promotionable}
        maxQuantity={maxQuantity}
        regionalRestrictionApplies={regionalRestrictionApplies}
        hasPrePurchaseStep={hasPrePurchaseStep}
        disclaimer={disclaimer}
        screener={screener}
        detailsBottomCallout={detailsBottomCallout}
        badges={badges}
        showRating={showShowRating}
      />
    </UserSettingsProvider>
  );
};
export default HeroContainer;
