import React from 'react';
import { ImageType } from 'utils/types';

import * as S from './CompactListSection.styles';

export type CompactListSectionProps = {
  listOfContentSections: {
    headline: string;
    image?: ImageType;
  }[];
};

/**
 * This section is meant to be used for the PDP page. It displays a list of icons and text.
 * To use this section, configure the Page Section entry on the PDP page to have
 * the **Component Name** field set to **CompactListSection**.
 */
function CompactListSection(props: CompactListSectionProps): JSX.Element {
  return (
    <S.OuterContainer className="container">
      <S.InnerContainer>
        {props.listOfContentSections.map((content, index) => (
          <S.ContentBlock key={index}>
            {content.image &&
              (content.image?.gatsbyImageData ? (
                <S.ImageWrapper>
                  <S.Image
                    image={{
                      ...content.image.gatsbyImageData,
                      width: 32,
                      height: 32,
                    }}
                    alt={content.image.description}
                  />
                </S.ImageWrapper>
              ) : (
                <S.ImageWrapper>
                  <img
                    src={content.image.file?.url}
                    alt={content.image.description}
                  />
                </S.ImageWrapper>
              ))}
            <S.Headline>{content.headline}</S.Headline>
          </S.ContentBlock>
        ))}
      </S.InnerContainer>
    </S.OuterContainer>
  );
}

export default CompactListSection;
