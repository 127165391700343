import { Button, size } from '@everlywell/leaves';
import { H4 } from '@everlywell/leaves';
import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled(H4)`
  font-weight: ${typography.weight.regular};
  margin-bottom: 1rem;
`;

const Body = styled.div`
  ${typography.bodyTextSmall}
  margin-bottom: 1.5rem;

  a {
    font-weight: ${typography.weight.regular};
    color: ${colors.green5};
    text-decoration: none;
  }

  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-left: ${size.lg}px;
  }
`;

const Footer = styled.div`
  width: 100%;
  padding-bottom: ${size.lg}px;
`;

const CTA = styled(Button)`
  width: 100%;
`;

const LinkWrapper = styled.div`
  padding-bottom: ${size.lg}px;
`;

export { LinkWrapper, CTA, Container, Title, Body, Footer };
