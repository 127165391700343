import React, { useContext, useEffect, useState } from 'react';
import { PageSectionType } from '../types';
import { filterPromosForMemberships } from '../helpers/membershipHelpers';
import { UserContext } from './useUserContext/context';

/*
  This hook takes in a PageSectionType array and returns:
    displayedSections: A filtered array of PageSectionType, removing any sections where the slug includes ActiveMembershipStatuses
    setUser: A React SetStateAction that is used to filter out PageSections based on the user membershipState
*/
const useDisplayedSectionFilterForMembers = (
  sections: PageSectionType[],
): [PageSectionType[]] => {
  const {
    userData: {
      activeCredits,
      fetched,
      firstName,
      lastName,
      isMember,
      loggedIn,
      membershipType,
      nextCreditDate,
      userEmail,
      userId,
      membershipState,
    },
  } = useContext(UserContext);
  const [displayedSections, setDisplayedSections] =
    useState<PageSectionType[]>(sections);
  useEffect(() => {
    if (fetched) {
      setDisplayedSections(
        sections.filter((section: PageSectionType) =>
          section.slug
            ? filterPromosForMemberships(true, section.slug, membershipState)
            : true,
        ),
      );
    }
  }, [
    activeCredits,
    fetched,
    firstName,
    lastName,
    isMember,
    loggedIn,
    membershipType,
    nextCreditDate,
    userEmail,
    userId,
    membershipState,
  ]);
  return [displayedSections];
};

export default useDisplayedSectionFilterForMembers;
