import React from 'react';

import {
  usePrePurchaseModalContext,
  PrePurchaseModalActionsTypes,
} from '../../contexts/PrePurchaseModal';
import GeneralDisclaimer from '../GeneralDisclaimer';
import PrePurchaseScreener from '../PrePurchaseScreener';

// Types ----------
type CallbackType = (
  e: React.FormEvent<HTMLButtonElement>,
  callback?: () => void,
) => void;
export interface PrePurchaseWrapperProps {
  addToCart?: CallbackType;
}

// PrePurchaseWrapper ------

const PrePurchaseWrapper: React.FC<PrePurchaseWrapperProps> = (props) => {
  const { addToCart } = props;

  const prePurchaseModalContext = usePrePurchaseModalContext();

  const {
    state: { generalDisclaimerData, prePurchaseScreenerData },
  } = prePurchaseModalContext;

  const closeScreenerHandler = () => {
    prePurchaseModalContext?.dispatch({
      type: PrePurchaseModalActionsTypes.SetPrePurchaseScreener,
      payload: {
        isPrePurchaseScreenerOpen: false,
      },
    });
  };

  /**
   * Toggle the disclaimer component
   * @param shouldOpen boolean value, true if it should be opened, false if it should close
   */
  const toggleDisclaimerHandler = (shouldOpen: boolean) => {
    prePurchaseModalContext?.dispatch({
      type: PrePurchaseModalActionsTypes.SetGeneralDisclaimer,
      payload: {
        isGeneralDisclaimerOpen: shouldOpen,
        generalDisclaimerData: generalDisclaimerData,
      },
    });
  };

  /**
   * Handles ineligible selection on screener
   */
  const ineligibleHandler = () => {
    prePurchaseModalContext?.dispatch({
      type: PrePurchaseModalActionsTypes.SetPrePurchaseScreener,
      payload: {
        isPrePurchaseScreenerOpen: false,
      },
    });

    toggleDisclaimerHandler(true);
  };

  /**
   * Handles eligible selection on screener
   * @param e React Event Object
   */
  const eligibleHandler = (e: React.FormEvent<HTMLButtonElement>) => {
    const elegibleCallback = prePurchaseModalContext?.state.elegibleCallback;

    if (typeof elegibleCallback === 'function') {
      elegibleCallback();
    } else if (addToCart) {
      addToCart(e);
    } else {
      throw new Error('Please set an eligible callback function');
    }
  };

  return (
    <>
      {generalDisclaimerData ? (
        <GeneralDisclaimer
          body={generalDisclaimerData.body}
          callToAction={generalDisclaimerData.callToAction}
          title={generalDisclaimerData.title}
          open={prePurchaseModalContext?.state.isGeneralDisclaimerOpen || false}
          openHandler={() => toggleDisclaimerHandler(false)}
        />
      ) : null}
      {prePurchaseScreenerData ? (
        <PrePurchaseScreener
          eligibleOptionCallback={(e) => eligibleHandler(e)}
          ineligibleOptionCallback={ineligibleHandler}
          modalOpen={
            prePurchaseModalContext?.state.isPrePurchaseScreenerOpen || false
          }
          modalOpenCallback={closeScreenerHandler}
          screenerData={prePurchaseScreenerData}
        />
      ) : null}
    </>
  );
};

// Export ---------

export default PrePurchaseWrapper;
