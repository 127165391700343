import { size, typography, mediaQueries } from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const spacingX = {
  mobile: size.md,
  tablet: size.lg,
  desktop: size.xl1,
};
const spacingY = {
  mobile: size.xl2,
  tablet: size.xl3,
  desktop: size.xl3,
};

export const OuterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const InnerContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  width: 100%;
  align-items: center;
  padding: ${spacingY.mobile}px 0 0 ${spacingX.mobile}px;
  margin: -${spacingY.mobile}px -${spacingX.mobile}px;
  ${mediaQueries.forTabletVerticalUp} {
    padding: ${spacingY.tablet}px 0 0 ${spacingX.tablet}px;
    margin: -${spacingY.tablet}px -${spacingX.tablet}px;
  }
  ${mediaQueries.forDesktopUp} {
    padding: ${spacingY.desktop}px 0 0 ${spacingX.desktop}px;
    margin: -${spacingY.desktop}px -${spacingX.desktop}px;
  }
`;

export const ContentBlock = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 ${spacingX.mobile}px ${spacingY.mobile}px 0;
  ${mediaQueries.forTabletVerticalUp} {
    width: 33.33%;
    padding: 0 ${spacingX.tablet}px ${spacingY.tablet}px 0;
  }
  ${mediaQueries.forDesktopUp} {
    width: 33.33%;
    padding: 0 ${spacingX.desktop}px ${spacingY.desktop}px 0;
  }
`;

export const ImageWrapper = styled.div`
  min-width: ${size.xl1}px;
`;

export const Image = styled(GatsbyImage)`
  display: flex;
`;

export const Headline = styled.p`
  font-family: ${typography.type.nexa};
  ${typography.subtitle}
  padding-left: ${size.sm}px;
`;
